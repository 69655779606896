<template>
  <div id="home">
    <div id="home-inner">
      <header>
        <img
          src="~@/assets/img/club-reclink-logo.svg"
          alt="Reclink Australia - Club Reclink"
          height="100"
        />
      </header>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#home {
  background-color: var(--color-primary);
  text-align: center;
  height: 100%;
  #home-inner {
    padding: 10px;
    header {
      h1 {
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
</style>
